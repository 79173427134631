export const setSupervisionFilter = (key, user) => {
  switch (key) {
    case 'IN_PROCESS':
      return 'status=REQUEST&status=WAITING';
    case 'PUBLISHED':
      return 'status=PUBLISHED';
    case 'BOOKED':
      return `status=BOOKED${
        user?.role === 'users:PENSIONER' ? '&status=IN_PROGRESS' : ''
      }`;
    case 'WAITING_BOOKING':
      return 'status=WAITING_BOOKING';
    case 'IN_PROGRESS':
      return 'status=IN_PROGRESS';
    case 'FINISHED':
      return 'status=FINISHED';
    case 'HISTORY':
      return 'status=FINISHED&status=CANCELED';
    default:
      return 'status=BOOKED&status=IN_PROGRESS';
  }
};

export const getSupervisions = async ({
  key,
  setIsLoading,
  dispatchAPI,
  profileRole,
  profile,
  user,
  message,
  setSupervisions
}) => {
  try {
    setIsLoading(true);

    const { data } = await dispatchAPI('GET', {
      url: `/supervisions?${profileRole}=${
        profile?._id
      }&sort=-main_informations.start_date&is_archived!=true&populate=address.petsitting_address&${setSupervisionFilter(
        key,
        user
      )}`
    });

    const updatedDataArray = [];
    const promise = await data.map(async (supervision) => {
      const { data: anounceData } = await dispatchAPI('GET', {
        url: `anounces?supervision=${supervision?._id}`
      });

      const updatedData = {
        ...supervision,
        anounce: anounceData.length ? anounceData[0] : null,
        petsitting_address: {
          ...data.petsitting_address
        }
      };

      updatedDataArray.push(updatedData);
    });
    await Promise.all(promise);

    const sortedDataArray = data.map((supervision) => {
      const matchingUpdatedData = updatedDataArray.find(
        (updatedData) => updatedData._id === supervision._id
      );
      return matchingUpdatedData;
    });

    setSupervisions(sortedDataArray);
    setIsLoading(false);
  } catch (e) {
    message(e);
  }
};
