const additionalInfo = (data, t) => {
  const {
    address,
    phone_number,
    cell_phone_number,
    email,
    is_animals,
    is_smoker,
    is_newsletters,
    moving_with_animal,
    secondary_profile
  } = data;

  return [
    {
      label: 'pensioners.form.address.street',
      span: 1,
      content:
        `${address?.number ? address.number : ''} ${
          address?.street ? address.street : ''
        }` || '-'
    },
    {
      label: 'pensioners.form.address.country',
      span: 1,
      content: (address?.country && address.country) || '-'
    },
    {
      label: 'pensioners.form.phone_number.number',
      span: 1,
      content: (phone_number?.number && phone_number.number) || '-'
    },
    {
      label: 'pensioners.form.cell_phone_number.number_1',
      span: 1,
      content: (cell_phone_number?.number && cell_phone_number.number) || '-'
    },
    {
      label: 'pensioners.form.cell_phone_number.number_2',
      span: 1,
      content:
        (secondary_profile?.cell_phone_number?.number &&
          secondary_profile?.cell_phone_number?.number) ||
        '-'
    },
    {
      label: 'pensioners.form.email',
      span: 1,
      content: (email && email) || '-'
    },
    {
      label: 'pensioners.form.is_animals',
      span: 1,
      content: is_animals ? t(`pensioners.form.yes`) : t(`pensioners.form.no`)
    },
    {
      label: 'pensioners.form.is_smoker',
      span: 1,
      content: is_smoker ? t(`pensioners.form.yes`) : t(`pensioners.form.no`)
    },
    {
      label: 'pensioners.form.is_newsletters',
      span: 1,
      content: is_newsletters
        ? t(`pensioners.form.yes`)
        : t(`pensioners.form.no`)
    },
    {
      label: 'pensioners.form.moving_with_animal',
      span: 1,
      content: moving_with_animal
        ? t(`pensioners.form.yes`)
        : t(`pensioners.form.no`)
    }
  ];
};

export { additionalInfo };
