import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import { message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import { supervisionReducer, initialState } from './reducer';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../AuthContext';
import { Owner } from '../../models/Owner';
import { Supervision } from '../../models/Supervision';

dayjs.extend(utc);

const SupervisionContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_INFORMATION_DATA':
      return {
        ...state,
        informationsData: action.payload
      };
    case 'SET_SUPERVISION_ADDRESS_DATA':
      return {
        ...state,
        supervisionAddressData: action.payload
      };
    case 'SET_SUMMARY_DATA':
      return {
        ...state,
        summaryData: action.payload
      };
    case 'RESET':
      return {
        informationsData: {},
        supervisionAddressData: {},
        summaryData: {}
      };
    default:
      return state;
  }
};

/**
 * Provides the context for supervision-related data and actions.
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The context provider component.
 */
export const SupervisionContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(supervisionReducer, initialState);
  const [
    { informationsData, supervisionAddressData, summaryData },
    supervisionDispatch
  ] = useReducer(reducer, {
    informationsData: {},
    supervisionAddressData: {},
    summaryData: {}
  });

  const { message } = useErrorMessage();
  const { dispatchAPI, user, ownerInfo, getUserProfiles } = useAuthContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const [selectedAnouncePhotos, setSelectedAnouncePhotos] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [templateType, setTemplateType] = useState('');
  const [documentType, setDocumentType] = useState(null);
  const [filterID, setFilterID] = useState(null);
  const [recordID, setRecordID] = useState(null);
  const [customerInvoices, setCustomerInvoices] = useState([]);
  const [owners, setOwners] = useState([]);
  const [owner, setOwner] = useState({});
  const [ownerId, setOwnerId] = useState('');
  const [supervisionsPrices, setSupervisionsPrices] = useState([]);
  const [supervisionPrice, setSupervisionPrice] = useState({});
  const [petsittingAddressFileList, setPetsittingAddressFileList] = useState(
    []
  );
  const [typeOfDwelling, setTypeOfDwelling] = useState('');
  const [environmentType, setEnvironmentType] = useState([]);

  const [enums, setEnums] = useState({});

  /**
   * Retrieves the supervision data for a given ID.
   * @async
   * @function
   * @param {string} id - The ID of the supervision.
   * @returns {Promise<void>} A promise that resolves when the data is retrieved.
   */
  const getSupervision = async (id) => {
    setIsLoading(true);
    const response = await Supervision.getOne(id);

    dispatch({ type: 'SET_SUPERVISION', payload: response });
    setSelectedAnouncePhotos(response.summary.anounce_photos);
    setIsLoading(false);
  };

  /**
   * Retrieves the announcement data for a given ID.
   * @async
   * @function
   * @param {string} id - The ID of the announcement.
   * @returns {Promise<void>} A promise that resolves when the data is retrieved.
   */
  const getAnounce = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/anounces/form?supervision=${id}&populate=supervision,supervision.summary.anounce_photos,supervision.address.petsitting_address`
      });
      if (data) {
        dispatch({ type: 'SET_ANOUNCE', payload: data });
      }
    } catch (error) {
      message(error);
    }
  };

  const getOwners = async () => {
    const response = await Owner.getAll({
      is_archived: false,
      fields: 'first_name,last_name,reference'
    });
    setOwners(response);
  };

  const getOwner = async () => {
    setIsFieldsLoading(true);

    const response = await Owner.getOne(ownerId, {
      populate: 'animals,petsitting_address',
      is_archived: false,
      fields: 'first_name,last_name,reference,petsitting_address,animals'
    });

    setOwner(response);
    setPetsittingAddressFileList(response.petsitting_address.dwelling_photos);
    setTypeOfDwelling(response.petsitting_address.type_of_dwelling);
    setEnvironmentType(response.petsitting_address.type_of_environment);
    setIsFieldsLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (user.role === 'users:OWNER') {
        await getUserProfiles();
        if (ownerInfo) {
          setOwnerId(ownerInfo._id);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (ownerId) await getOwner();
    })();
  }, [ownerId]);

  const getSupervisionsPrices = async () => {
    const response = await Supervision.getSupervisionsPrices();

    setSupervisionsPrices(response);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([getSupervisionsPrices(), getOwners()]);
    })();
  }, []);

  const getEnums = useCallback(async () => {
    try {
      const { data: ownerEnums } = await dispatchAPI('GET', {
        url: '/owners/enums'
      });
      setEnums((prevState) => ({ ...prevState, ...ownerEnums }));
      const { data: supervisionEnums } = await dispatchAPI('GET', {
        url: '/supervisions/enums'
      });
      setEnums((prevState) => ({ ...prevState, ...supervisionEnums }));
      const { data: petsittingAddressEnums } = await dispatchAPI('GET', {
        url: '/petsittingaddresses/enums'
      });
      setEnums((prevState) => ({ ...prevState, ...petsittingAddressEnums }));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, [getEnums]);

  const postSupervision = async (purpose, supervisionId = null) => {
    try {
      const formData = new FormData();

      const startDate = informationsData.main_informations.start_date;
      const endDate = informationsData.main_informations.end_date;

      const formattedStartDate = dayjs(startDate)
        .utcOffset(0)
        .year(startDate.year())
        .month(startDate.month())
        .date(startDate.date())
        .hour(startDate.hour())
        .minute(startDate.minute());

      const formattedEndDate = dayjs(endDate)
        .utcOffset(0)
        .year(endDate.year())
        .month(endDate.month())
        .date(endDate.date())
        .hour(endDate.hour())
        .minute(endDate.minute());

      const newSelectedAnnoncePhotos = selectedAnouncePhotos.map((photo) => {
        if (photo?.file) {
          return { name: photo.file.name };
        }
        return photo;
      });

      const body = {
        main_informations: {
          ...informationsData.main_informations,
          start_date: formattedStartDate,
          end_date: formattedEndDate
        },
        petsitting_address: supervisionAddressData.petsitting_address,
        summary: {
          ...summaryData.summary,
          anounce_photos: newSelectedAnnoncePhotos,
          price_category: {
            ...summaryData.summary.price_category,
            supervision_price: supervisionPrice.supervision_price,
            reduction_ttc: supervisionPrice.reduction_ttc
          }
        }
      };

      const existingDwellingPhotos = () => {
        let findDwellingPhotos;
        if (!supervisionAddressData?.petsittingaddress?.dwelling_photos) {
          findDwellingPhotos = selectedAnouncePhotos.filter((item) =>
            supervisionAddressData?.petsitting_address?.dwelling_photos?.some(
              (photo) => photo === item
            )
          );
        }
        return findDwellingPhotos;
      };
      const newDwellingPhotos =
        supervisionAddressData?.petsittingaddress?.dwelling_photos?.fileList
          .map((file) => file?.file)
          .filter((item) => item);

      formData.append('values', JSON.stringify(body));
      newDwellingPhotos?.forEach((file) => {
        formData.append('dwelling_photos', file);
      });
      existingDwellingPhotos()?.forEach((file) => {
        formData.append('dwelling_photos', file);
      });

      const setApiCall = () => {
        switch (purpose) {
          case 'create':
            return { url: '/supervisions/form', action: 'POST' };
          case 'edit':
            return {
              url: `/supervisions/form/${supervisionId}`,
              action: 'PATCH'
            };
          default:
            return { url: '/supervisions', action: 'POST' };
        }
      };

      await dispatchAPI(setApiCall().action, {
        url: setApiCall().url,
        body: formData
      });

      antdMessage.success(t('supervisions.messages.success.email_sent'));
      antdMessage.success(t(`supervisions.messages.success.${purpose}`));

      supervisionDispatch({ type: 'RESET' });

      return navigate('/supervisions');
    } catch (e) {
      if (
        e?.response?.data?.description ===
        'CONFIRMATION_EMAIL_NOT_SENT_SUPERVISION_CREATED'
      ) {
        navigate('/supervisions');
      }
      return message(e);
    }
  };

  return (
    <SupervisionContext.Provider
      value={{
        state,
        dispatch,
        getSupervision,
        getOwner,
        isLoading,
        setIsLoading,
        getAnounce,
        refreshData,
        setRefreshData,
        selectedAnouncePhotos,
        setSelectedAnouncePhotos,
        recipients,
        setRecipients,
        templateType,
        setTemplateType,
        documentType,
        setDocumentType,
        filterID,
        setFilterID,
        recordID,
        setRecordID,
        customerInvoices,
        setCustomerInvoices,
        supervisionsPrices,
        owners,
        owner,
        setOwner,
        ownerId,
        setOwnerId,
        isFieldsLoading,
        setIsFieldsLoading,
        enums,
        petsittingAddressFileList,
        setPetsittingAddressFileList,
        typeOfDwelling,
        setTypeOfDwelling,
        environmentType,
        setEnvironmentType,
        postSupervision,
        supervisionPrice,
        setSupervisionPrice,
        supervisionDispatch,
        summaryData,
        setStatus: (status) =>
          dispatch({ type: 'SET_STATUS', payload: status }),
        setCurrent: (current) =>
          dispatch({ type: 'SET_CURRENT', payload: current }),
        setAnounceModalVisible: (visible) =>
          dispatch({ type: 'SET_ANOUNCE_MODAL_VISIBLE', payload: visible }),
        setCancelSupervisionModalVisible: (visible) =>
          dispatch({
            type: 'SET_CANCEL_SUPERVISION_MODAL_VISIBLE',
            payload: visible
          }),
        setInterruptSupervisionModalVisible: (visible) =>
          dispatch({
            type: 'SET_INTERRUPT_SUPERVISION_MODAL_VISIBLE',
            payload: visible
          }),
        setIsEmailModalOpen: (visible) =>
          dispatch({ type: 'SET_IS_EMAIL_MODAL_OPEN', payload: visible })
      }}
    >
      {children}
    </SupervisionContext.Provider>
  );
};

export const useSupervisionContext = () => useContext(SupervisionContext);
