import { Card, Col, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../../components';
import { veterinarianInfo } from '../listContent/veterinarianInfo';
import { TemplateDocumentButton } from '../../../../../components/TemplateDocumentButton/TemplateDocumentButton';

/**
 * Component for displaying veterinarian information in a card.
 *
 * @param {Object} props - The component's props.
 * @param {boolean} props.isLoading - Indicates if the data is currently loading.
 * @param {Object} props.veterinarian - The veterinarian object to display.
 * @returns {JSX.Element} - The rendered VeterinarianCard component.
 */
export const VeterinarianCard = ({ veterinarian, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading}>
      <Card
        title={t('supervisions.show.veterinarian.title')}
        extra={
          <TemplateDocumentButton
            parameters={[
              { collection: 'Veterinarian', documentId: veterinarian?._id }
            ]}
            use_case="VETERINARIAN_SYNTHESIS"
          />
        }
      >
        <Row>
          <Col span={24}>
            <DescriptionList
              data={veterinarianInfo(veterinarian, t)}
              translate
            />
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

VeterinarianCard.propTypes = {
  veterinarian: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
};
