import { DatePicker, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

/**
 * Custom hook that returns the fields for the review form.
 * @hook
 * @param {Object} options - The options object.
 * @param {Object} options.supervision - The supervision object.
 * @param {Function} options.setQuestionnaireType - The function to set the questionnaire type.
 * @param {string} options.questionnaireType - The questionnaire type.
 * @param {Function} options.setRatingDate - The function to set the rating date.
 * @param {boolean} options.showReviewDrawer - A flag indicating whether the review drawer is shown.
 * @param {Array} options.supervisions - The array of supervisions.
 * @param {Function} options.setSupervision - The function to set the supervision.
 * @returns {Object} - The fields for the review form.
 */
export const useFields = ({
  supervision = {},
  setQuestionnaireType,
  questionnaireType,
  setRatingDate,
  showReviewDrawer,
  supervisions,
  setSupervision
}) => {
  const { t } = useTranslation();

  const pensionerName = supervision?.pensioners?.map((item) => ({
    first_name: item.first_name,
    last_name: item.last_name,
    id: item._id
  }));

  const { first_name, last_name } = supervision?.main_informations?.owner || {};

  const questionnaireTypeOptions = [
    {
      value: 'users:PENSIONER',
      label: t('users.tags.PENSIONER')
    },
    {
      value: 'users:OWNER',
      label: t('users.tags.OWNER')
    }
  ];

  const authorOptions = () => {
    if (questionnaireType === 'users:PENSIONER') {
      return [
        ...(pensionerName || []).map((item) => ({
          value: item.id,
          label: `${item.last_name} ${item.first_name}`
        }))
      ];
    }
    if (questionnaireType === 'users:OWNER') {
      return [
        {
          value: supervision?.main_informations?.owner?._id,
          label: `${last_name} ${first_name}`
        }
      ];
    }
    return null;
  };

  const receiverOptions = () => {
    if (questionnaireType === 'users:PENSIONER') {
      return [
        {
          value: supervision?.main_informations?.owner?._id,
          label: `${last_name} ${first_name}`
        }
      ];
    }
    if (questionnaireType === 'users:OWNER') {
      return [
        ...(pensionerName || []).map((item) => ({
          value: item.id,
          label: `${item.last_name} ${item.first_name}`
        }))
      ];
    }
    return null;
  };

  const getSelect = (options, fieldType) => (
    <Select
      disabled={showReviewDrawer}
      options={options}
      mode={
        questionnaireType === 'users:OWNER' && fieldType === 'receiver'
          ? 'multiple'
          : null
      }
    />
  );

  const infosSupervisionFields = [
    {
      name: ['supervision', 'reference'],
      label: 'reference',
      initialValue: supervision?._id,
      rules: [{ required: true }],
      input: (
        <Select
          disabled={showReviewDrawer}
          onChange={(e, option) => setSupervision(option.supervision)}
        >
          {supervisions.map((item) => (
            <Option value={item?._id} key={item?._id} supervision={item}>
              {item.reference}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['questionnaire_type'],
      label: 'questionnaire_type',
      input: (
        <Select
          disabled={showReviewDrawer}
          options={questionnaireTypeOptions}
          onChange={(value) => setQuestionnaireType(value)}
        />
      ),
      rules: [{ required: true }]
    },
    {
      name: ['author', 'id'],
      label: 'author',
      input: getSelect(authorOptions(), 'author'),
      rules: [{ required: true }]
    },
    {
      name: ['receiver', ['id']],
      label: 'receiver',
      input: getSelect(receiverOptions(), 'receiver'),
      rules: [{ required: true }]
    },
    {
      name: ['date'],
      label: 'date',
      input: (
        <DatePicker
          disabled={showReviewDrawer}
          onChange={(value) => setRatingDate(value)}
          format="DD/MM/YYYY"
        />
      )
    },
    {
      name: ['rating'],
      label: 'rating',
      input: <Input type="number" disabled={showReviewDrawer} />
    },
    {
      name: ['comment_or_suggestion'],
      label: 'comment_or_suggestion',
      input: <Input.TextArea disabled={showReviewDrawer} />
    }
  ];

  return {
    infosSupervisionFields
  };
};
