import { Button, Tabs, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CardsList } from './CardsList';
import { useCallsAPI } from '../utils/callAPI';
import { getSupervisions } from './utils/getSupervisions';

/**
 * Renders a list of supervisions for the current owner user.
 * @component
 * @returns {JSX.Element} The rendered ListMySupervisions component.
 */
export const ListMySupervisions = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState();
  const [profileRole, setProfileRole] = useState('');

  const navigate = useNavigate();
  const [supervisions, setSupervisions] = useState([]);
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();

  const route = `${user?.role?.split(':')[1].toLowerCase()}s`;

  const pensionersTabs = ['BOOKED', 'WAITING_BOOKING', 'HISTORY'];
  const ownersTabs = [
    'IN_PROCESS',
    'PUBLISHED',
    'BOOKED',
    'IN_PROGRESS',
    'FINISHED'
  ];
  const tabs = user?.role === 'users:PENSIONER' ? pensionersTabs : ownersTabs;
  const defaultTabKey = user?.role === 'users:OWNER' ? 'IN_PROCESS' : 'BOOKED';

  const { getProfile } = useCallsAPI({
    setFunction: (data) => setProfile(data[0])
  });

  useEffect(() => {
    const role = route === 'owners' ? 'owner' : null;
    if (role) setProfileRole(role);
    else setProfileRole(route);
  }, [profile]);

  const onChange = (key) => {
    getSupervisions({
      key,
      setIsLoading,
      dispatchAPI,
      profileRole,
      profile,
      user,
      message,
      setSupervisions
    });
  };

  useEffect(() => {
    (async () => {
      await getProfile();
    })();
  }, []);

  useEffect(async () => {
    if (profile)
      await getSupervisions({
        key: defaultTabKey,
        setIsLoading,
        dispatchAPI,
        profileRole,
        profile,
        user,
        message,
        setSupervisions
      });
  }, [profile]);

  return (
    <>
      <PageHeaderCustom
        withoutBreadCrumb
        onBack="/"
        title={t('owners.users_shows.title.my_supervisions')}
        extra={
          user?.role === 'users:OWNER' && (
            <Button
              type="primary"
              onClick={() => {
                if (profile?.animals?.length) {
                  return navigate('/supervisions/create');
                }
                return notification.warning({
                  message: t('owners.users_shows.messages.create_animals')
                });
              }}
            >
              <PlusOutlined />
              {t('owners.users_shows.buttons.new_supervision_request')}
            </Button>
          )
        }
      />
      <ContentCustom>
        <Tabs
          type="card"
          onChange={onChange}
          items={tabs.map((el) => ({
            label: t(`supervisions.users_shows.tabs.${el}`),
            key: el,
            children: (
              <CardsList
                supervisions={supervisions}
                isLoading={isLoading}
                tabsKey={el}
              />
            )
          }))}
        />
      </ContentCustom>
    </>
  );
};
