import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Col,
  Flex,
  Image,
  Row,
  Typography,
  Carousel,
  Skeleton
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DescriptionList } from '../../../components';
import { dateFormat } from './utils/dateFormat';
import { detailsCards } from './utils/detailsCards';
import { useAuthContext } from '../../../contexts/AuthContext';
import { setMissingSupervisionsMessage } from './utils/setMissingSupervisionMessage';
import { convertToHTML } from '../../../utils/convertToHTML';

const { Title } = Typography;

export const CardsList = ({ supervisions = [], isLoading, tabsKey }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, token } = useAuthContext();

  if (!supervisions.length && !isLoading) {
    return (
      <Title level={2}>
        {setMissingSupervisionsMessage({ user, tabsKey, t })}
      </Title>
    );
  }

  return (
    <Skeleton
      loading={isLoading}
      active
      avatar={{ shape: 'square', size: 150 }}
    >
      <Flex vertical gap="middle" align="stretch">
        {supervisions.map((supervision) => (
          <React.Fragment key={supervision?._id}>
            <Card
              key={supervision?._id}
              hoverable
              onClick={() =>
                navigate(`/supervisions/details/${supervision?._id}`)
              }
              extra={
                <Typography.Text>
                  {t(`supervisions.tags.${supervision?.status}`)}
                </Typography.Text>
              }
              className="supervision-list-card"
            >
              <Row>
                <Col xs={24} lg={6}>
                  <Carousel autoplay span={1}>
                    {supervision?.summary?.anounce_photos?.map((photo) => (
                      <Image
                        key={photo?._id}
                        width={150}
                        src="error"
                        fallback={`${process.env.REACT_APP_API_URL}/supervisions/get-url/${photo}/${token}`}
                      />
                    ))}
                  </Carousel>
                </Col>
                <Col style={{ maxWidth: '100%' }} xs={24} lg={18}>
                  <DescriptionList
                    title={
                      <Title level={3} style={{ whiteSpace: 'wrap' }}>
                        {t(`supervisions.users_shows.supervision_date`, {
                          reference: supervision?.reference,
                          ...dateFormat(
                            supervision?.main_informations?.start_date,
                            supervision?.main_informations?.end_date,
                            user?.role
                          )
                        })}
                      </Title>
                    }
                    data={detailsCards(tabsKey, t, supervision)}
                    withDivider
                    comment={convertToHTML({
                      htmlContent: supervision?.anounce?.description
                    })}
                    layout="horizontal"
                  />
                </Col>
              </Row>
            </Card>
          </React.Fragment>
        ))}
      </Flex>
    </Skeleton>
  );
};

CardsList.propTypes = {
  supervisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  tabsKey: PropTypes.string
};

CardsList.defaultProps = {
  tabsKey: undefined
};
