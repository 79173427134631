import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col, Button } from 'antd';
import { generalAnimalInfo } from '../../../../../components/Animals/listContent/animalInfo';
import { DescriptionList } from '../../../../../components';
import AnimalInfoModal from '../modals/AnimalInfoModal';
import { Carousel } from '../../../../../components/Carousel/Carousel';

/**
 * `AnimalInfoCards` is a component that displays a series of cards containing information
 * about different animals. Each card contains a carousel of images and a list of descriptions.
 *
 * It also manages a modal that can be opened to view more information about a particular animal.
 *
 * @component
 * @param {object} props The properties passed down from the parent component
 * @param {Function} props.t A function to handle the translation of strings
 * @param {boolean} props.isLoading A boolean flag to indicate whether data is currently being loaded
 * @param {object} props.owner An object representing the owner of the animals, containing an array of animal objects
 * @param {Function} props.getColWidth A function to determine the column width based on the animal count
 *
 * @returns {ReactElement} JSX representation of the component
 */
const AnimalInfoCards = ({ t, isLoading, owner, getColWidth }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [animal, setAnimal] = useState(null);
  const [modalIndex, setModalIndex] = useState();
  const animalCount = owner?.animals.length;

  const showModal = (index, animalToDisplay) => {
    setAnimal(animalToDisplay);
    setOpenIndex(index);
    setModalIndex(index);
  };

  return (
    <Row gutter={[12, 12]}>
      {owner?.animals.map((ownerAnimal, index) => (
        <Col key={ownerAnimal?._id} xs={24} xxl={getColWidth(animalCount)}>
          <Card
            title={t('owners.show.animal_info', {
              animal_number: index + 1
            })}
            extra={
              <Button
                onClick={() => showModal(index, ownerAnimal)}
                size="small"
              >
                {t('owners.buttons.show_more')}
              </Button>
            }
          >
            <Row gutter={[24, 24]}>
              <Col xs={24} md={8}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <Carousel imagesIds={ownerAnimal?.animal_photo} />
                </Skeleton>
              </Col>
              <Col xs={24} md={16}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <DescriptionList
                    data={generalAnimalInfo(ownerAnimal || {}, t)}
                    translate
                    skipEmpty
                  />
                </Skeleton>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
      <AnimalInfoModal
        animal={animal}
        openIndex={openIndex}
        setOpenIndex={setOpenIndex}
        t={t}
        index={modalIndex}
      />
    </Row>
  );
};

export default AnimalInfoCards;

AnimalInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  owner: PropTypes.shape({
    animals: PropTypes.arrayOf(
      PropTypes.shape({
        animal_photo: PropTypes.arrayOf(PropTypes.shape({}))
      })
    ).isRequired
  }).isRequired,
  getColWidth: PropTypes.func.isRequired
};
