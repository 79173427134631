import { Card, List, Badge } from 'antd';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { handleNotifMenuItemLink } from '../../../../../components/Notifications/handleNotificationMenuLink';
import { useDeleteNotification } from '../../../../../utils/deleteNotification';

/**
 * GeneralAlerts component for displaying general alerts.
 * @component
 * @param {Object} props - The properties of the GeneralAlerts component.
 * @param {Array} props.notifications - An array of notifications.
 * @param {Function} props.t - Translation function for internationalization.
 * @param {Function} props.dispatchAPI - Function for dispatching API calls.
 * @param {boolean} props.forceRefresh - Boolean flag for forcing a data refresh.
 * @param {Function} props.setForceRefresh - Function to set the forceRefresh state.
 * @param {Function} props.message - Function for displaying messages.
 * @param {Object} props.user - User object containing user information.
 * @param {string} props.user._id - User ID.
 * @returns {JSX.Element} - JSX element representing the GeneralAlerts component.
 */
export const GeneralAlerts = ({
  notifications,
  t,
  dispatchAPI,
  forceRefresh,
  setForceRefresh,
  message,
  user
}) => {
  const [generalNotifications, setGeneralNotifications] = useState([]);

  /**
   * Custom hook for handling notification deletion.
   * @type {Object}
   */
  const { deleteNotification } = useDeleteNotification({
    dispatchAPI,
    forceRefresh,
    setForceRefresh,
    message
  });

  useEffect(() => {
    setGeneralNotifications(
      notifications.filter(
        (notification) =>
          notification.item.ref !== 'Supervision' &&
          notification.item.type !== null
      )
    );
  }, [notifications]);

  const filteredNotif = generalNotifications.map(
    (notif) => notif.consulted.filter((id) => id === user._id).length
  );
  const numberOfNotifNotConsulted = filteredNotif.filter(
    (item) => item === 0
  ).length;

  return (
    <Card
      title={t('notifications.other_alerts')}
      extra={<Badge count={numberOfNotifNotConsulted} />}
      className="dashboard-card"
    >
      <List
        dataSource={generalNotifications}
        renderItem={(item) =>
          handleNotifMenuItemLink(
            item._id,
            deleteNotification,
            t,
            item.created_at,
            item.consulted,
            item.item
          )
        }
        className="alert-list"
      />
    </Card>
  );
};

GeneralAlerts.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired
};
