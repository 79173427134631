import { Button, Popconfirm, message } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useSocketContext } from '../../../../contexts/SocketContext';
import { profileLockMessage } from '../../utils/profileLockMessage';
import { editingLocked } from '../../../../utils/editingLocked';

export const ExtraCollapseEditButton = ({ animal, t }) => {
  const navigate = useNavigate();
  const { profileLock } = useSocketContext();
  const { id } = useParams();

  return (
    <Button
      type="primary"
      onClick={() => {
        if (editingLocked(profileLock, id)) {
          profileLockMessage(t, 'profile');
          return;
        }
        navigate(`/my-pets/${id}/edit/${animal?._id}`);
      }}
    >
      <EditOutlined />
      {t('buttons.edit')}
    </Button>
  );
};

export const ExtraCollapseDeleteButton = ({ animal, t, setRefresh }) => {
  const { dispatchAPI } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();

  const confirm = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/animals/${animal._id}` });
      message.success(t('owners.users_shows.messages.success_delete_animal'));
      setRefresh(true);
    } catch (e) {
      errorMessage(e);
    }
  };

  const cancel = () => {
    message.error('Click on No');
  };

  return (
    <Popconfirm
      title={t('animals.users_show.messages.delete_title')}
      description={t('animals.users_show.messages.confirm_delete')}
      onConfirm={confirm}
      onCancel={cancel}
      okText={t('files.modal.ok')}
      cancelText={t('files.modal.cancel')}
    >
      <Button type="link" danger>
        <CloseOutlined />
        {t('buttons.delete')}
      </Button>
    </Popconfirm>
  );
};

ExtraCollapseEditButton.propTypes = {
  animal: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};

ExtraCollapseDeleteButton.propTypes = {
  animal: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired
};
