import { useState } from 'react';
import { Card, Col, Row, Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../../components';
import { generalAnimalInfo } from '../../../../../components/Animals/listContent/animalInfo';
import { generalOldAnimalInfo } from '../../../../../components/Animals/listContent/oldAnimalInfo';
import AnimalsInfoModal from '../modals/AnimalsInfoModal';

/**
 * Renders a card component displaying a list of animals.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.animals - The list of animals to display.
 * @param {boolean} props.isLoading - Indicates if the data is currently loading.,
 * @param {Object} props.old_data_animals - The old data of the animals.
 * @returns {JSX.Element} The rendered AnimalCard component.
 */
export const AnimalCard = ({ animals, isLoading, old_data_animals }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading}>
      <Card
        title={t('supervisions.show.animals_to_keep')}
        extra={
          animals?.length ? (
            <Button onClick={() => setIsModalOpen(true)} size="small">
              {t('owners.buttons.show_more')}
            </Button>
          ) : null
        }
      >
        {Object.keys(old_data_animals).length ? (
          <Col span={24}>
            <DescriptionList
              data={generalOldAnimalInfo(old_data_animals)}
              translate
            />
          </Col>
        ) : (
          <Row style={{ height: '200px', overflowY: 'auto' }}>
            {animals.map((animal) => (
              <Col span={24}>
                <DescriptionList
                  data={generalAnimalInfo(animal, t)}
                  translate
                  skipEmpty
                />
              </Col>
            ))}
          </Row>
        )}
      </Card>
      {animals?.length ? (
        <AnimalsInfoModal
          animals={animals}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </Spin>
  );
};

AnimalCard.propTypes = {
  animals: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  old_data_animals: PropTypes.shape({})
};

AnimalCard.defaultProps = {
  animals: [],
  old_data_animals: {}
};
