import dayjs from 'dayjs';

/**
 * Submits a credit note form to create a new credit note based on the provided data.
 *
 * @param {Object} options - Options for submitting a credit note.
 * @param {Object} options.body - The data for the credit note.
 * @param {Object} options.invoice - The associated invoice for the credit note.
 * @param {number} options.totalHT - The total amount excluding tax for the credit note.
 * @param {number} options.totalTTC - The total amount including tax for the credit note.
 * @param {Function} options.dispatchAPI - Function for making API requests.
 * @param {Function} options.message - Function to display error messages.
 * @param {Function} options.setIsCreditNoteCardVisible - Function to toggle the visibility of the credit note card.
 * @param {boolean} options.isCreditNoteCardVisible - The current visibility state of the credit note card.
 * @param {Function} options.navigate - Function to navigate to a new route.
 * @param {Object} options.wordingsIds - The IDs of the selected wordings for the credit note.
 * @returns {Promise<void>} A promise that resolves once the credit note is submitted successfully.
 */
export const handleSubmitCreditNote = async ({
  body,
  invoice,
  totalHT,
  totalTTC,
  dispatchAPI,
  message,
  setIsCreditNoteCardVisible,
  isCreditNoteCardVisible,
  navigate,
  wordingsIds
}) => {
  const formData = new FormData();
  try {
    // Prepare data for the credit note
    const newBody = {
      ...body,
      total_ht: totalHT,
      total_ttc: totalTTC,
      wordings: body.wordings && Object.values(body.wordings),
      customer: invoice.customer._id,
      emission_date: dayjs(),
      associated_invoice: invoice._id,
      status: 'WAITING',
      city: invoice?.customer?.city,
      invoicing_address: invoice?.customer?.invoicing_address,
      postal_code: invoice?.customer?.postal_code,
      supervision: invoice?.supervision?._id,
      selected_wordings: wordingsIds
    };

    // Append the data to the FormData
    formData.append('values', JSON.stringify(newBody));

    // Submit the credit note form to create a new credit note
    const { data } = await dispatchAPI('POST', {
      url: 'customerinvoices/form',
      body: formData
    });

    // Toggle the visibility of the credit note card and navigate to the new credit note
    if (data) {
      setIsCreditNoteCardVisible(!isCreditNoteCardVisible);
      navigate(`/invoices/customers/show/${data._id}`);
    }
  } catch (err) {
    message(err);
  }
};
