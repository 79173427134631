import { useTranslation } from 'react-i18next';
import { DatePicker, Form, Input, Select, Space, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Dragger } = Upload;

/**
 * Custom hook for generating secondary profile fields used in profile creation/update form.
 * @param {Object} params - Parameters for the hook.
 * @param {function} params.setDocumentsFileList - Function to set the documents file list.
 * @param {Object} params.documentsFileList - Current documents file list.
 * @param {Array} params.secondaryProfilePhoto - Array of secondary profile photos.
 * @param {function} params.setSecondaryProfilePhoto - Function to set the secondary profile photos array.
 * @returns {Object} - Object containing secondaryProfileFields array.
 */
export const useSecondaryProfileFields = ({
  setDocumentsFileList,
  documentsFileList,
  setRemovedFiles
}) => {
  const { t } = useTranslation();

  /**
   * Function to set documents dragger properties.
   * @param {string} object - The object key for documentsFileList.
   * @param {string} name - The name of the document.
   * @returns {Object} - Object containing properties for the documents Dragger component.
   */
  const documentsDraggerProps = (object, name) => ({
    onRemove: () => {
      const updatedFileList = { ...documentsFileList };

      setRemovedFiles((prevRemovedFiles) => [
        ...prevRemovedFiles,
        updatedFileList[object][name][0].id
      ]);

      setDocumentsFileList(updatedFileList);

      delete updatedFileList[object][name];
    },
    beforeUpload: (file) => {
      setDocumentsFileList((prevFileList) => {
        if (object in prevFileList) {
          return {
            ...prevFileList,
            [object]: {
              ...prevFileList[object],
              [name]: [file]
            }
          };
        }
        return {
          ...prevFileList,
          [object]: {
            [name]: [file]
          }
        };
      });

      return false;
    },
    fileList: documentsFileList?.[object] && documentsFileList?.[object]?.[name]
  });

  const secondaryProfileFields = [
    {
      name: ['secondary_profile', 'civility'],
      label: 'civility',
      rules: [{ required: true }],
      input: (
        <Select
          options={[
            { label: t('users.tags.MR'), value: 'MR' },
            { label: t('users.tags.MRS'), value: 'MRS' }
          ]}
        />
      )
    },
    {
      name: ['secondary_profile', 'last_name'],
      label: 'last_name',
      rules: [{ required: true }]
    },
    {
      name: ['secondary_profile', 'first_name'],
      label: 'first_name',
      rules: [{ required: true }]
    },

    {
      name: ['secondary_profile', 'birth_date'],
      label: 'birth_date',
      rules: [{ required: true }],
      input: (
        <DatePicker
          format="DD/MM/YYYY"
          panelRender={() => null}
          preserveInvalidOnBlur
          placeholder="JJ/MM/AAAA"
        />
      )
    },
    {
      name: ['secondary_profile', 'cell_phone_number'],
      label: 'cell_phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['secondary_profile', 'cell_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['secondary_profile', 'cell_phone_number', 'number']}
          >
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['secondary_profile', 'profession'],
      label: 'profession'
    },
    {
      name: ['secondary_profile', 'principal_language'],
      label: 'principal_language'
    },
    {
      label: 'criminal_record_extract',
      input: (
        <Dragger
          {...documentsDraggerProps(
            'secondary_profile',
            'secondary_profile_criminal_record_extract'
          )}
        >
          <Space>
            <UploadOutlined />
            {t('users.form.upload_file')}
          </Space>
        </Dragger>
      )
    },
    {
      label: 'identification_photocopy',
      input: (
        <Dragger
          {...documentsDraggerProps(
            'secondary_profile',
            'secondary_profile_identification_photocopy'
          )}
        >
          <Space>
            <UploadOutlined />
            {t('users.form.upload_file')}
          </Space>
        </Dragger>
      )
    },
    {
      label: 'identification_photo',
      input: (
        <Dragger
          {...documentsDraggerProps(
            'secondary_profile',
            'secondary_profile_identification_photo'
          )}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  return { secondaryProfileFields };
};
