import React from 'react';
import PropTypes from 'prop-types';
import { Col, Modal } from 'antd';
import { detailAnimalInfo } from '../../../../../components/Animals/listContent/animalInfo';
import { DescriptionList } from '../../../../../components';
import { Carousel } from '../../../../../components/Carousel/Carousel';

/**
 * `AnimalInfoModal` is a component that displays a modal with detailed information about a specific animal.
 * The modal contains a carousel of images and a description list.
 *
 * @component
 * @param {Object} props - The properties passed down from the parent component.
 * @param {Object} props.owner - An object containing details about the owner and an array of animal objects.
 * @param {boolean} props.isModalOpen - A boolean flag indicating whether the modal is open.
 * @param {Function} props.setIsModalOpen - A function to set the `isModalOpen` state.
 * @param {Function} props.t - A function to handle the translation of strings.
 * @param {number} props.index - The index of the selected animal in the owner's animals array.
 *
 * @returns {React.Element} JSX representation of the component.
 */
const AnimalInfoModal = ({ animal, openIndex, setOpenIndex, t, index }) => (
  <Modal
    title={t('owners.show.animal_info', {
      animal_number: index + 1
    })}
    open={openIndex === index}
    onOk={() => setOpenIndex(false)}
    onCancel={() => setOpenIndex(false)}
  >
    <Col xs={24} xxl={24} style={{ marginBottom: 16 }}>
      <Carousel imagesIds={animal?.animal_photo} />
    </Col>
    <Col xs={24} xxl={24}>
      <DescriptionList data={detailAnimalInfo(animal, t)} translate skipEmpty />
    </Col>
  </Modal>
);

export default AnimalInfoModal;

AnimalInfoModal.propTypes = {
  animal: PropTypes.shape({
    animal_photo: PropTypes.arrayOf(PropTypes.string)
  }),
  openIndex: PropTypes.string,
  setOpenIndex: PropTypes.func,
  t: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

AnimalInfoModal.defaultProps = {
  animal: {},
  openIndex: false,
  setOpenIndex: null
};
