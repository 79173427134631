import React from 'react';
import { Button, Col, List, Row, Skeleton } from 'antd';
import { CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { generateDocumentsOld } from '../../mail-sender/utils/generateDocumentsOld';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * React component for displaying a list of documents.
 * @component
 * @param {Object} props - The component properties.
 * @param {boolean} props.isLoading - Indicates whether documents are in a loading state.
 * @param {Array} props.documents - Array of document objects.
 * @returns {JSX.Element} - React component.
 * @example
 * // Example usage of the ListInvoiceDocuments component:
 * <ListInvoiceDocuments
 *   isLoading={loading}
 *   documents={documentList}
 * />
 */
export const ListInvoiceDocuments = ({ isLoading, documents, user }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const filteredDocuments = documents.filter((doc) => {
    if (user.role === 'users:PENSIONER') {
      return doc.type === 'SUBSCRIPTION';
    }
    const docType = ['BALANCE', 'ADVANCE_PAYMENT'];
    return docType.includes(doc.type);
  });

  return (
    <Row>
      <Col span={16}>
        <List
          className="demo-loadmore-list"
          loading={isLoading}
          itemLayout="horizontal"
          dataSource={filteredDocuments}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() =>
                    generateDocumentsOld(
                      dispatchAPI,
                      message,
                      item.document_type,
                      item._id,
                      null,
                      t
                    )
                  }
                >
                  <DownloadOutlined />
                </Button>
              ]}
            >
              <Skeleton avatar title={false} loading={item?.loading} active>
                <List.Item.Meta
                  avatar={<CheckCircleOutlined />}
                  description={t(
                    `dashboard.show.document.${
                      item?.type === 'SUBSCRIPTION'
                        ? 'description_subscription_invoice'
                        : 'description_invoice'
                    }`,
                    {
                      type: t(`customerinvoices.tags.${item?.type}`),
                      reference: item?.supervision?.reference,
                      year: dayjs(item?.subscription?.start_date).format('YYYY')
                    }
                  )}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

ListInvoiceDocuments.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      metadata: PropTypes.shape({
        originalName: PropTypes.string.isRequired
      })
    })
  ),
  user: PropTypes.shape({
    role: PropTypes.string
  }).isRequired
};

ListInvoiceDocuments.defaultProps = {
  documents: []
};
