/**
 * Fetches the subscription and related invoice for a given pensioner and updates the state accordingly.
 * @async
 * @function
 *
 * @param {Object} params - The parameters for the function.
 * @param {Function} params.dispatchAPI - The function to dispatch API calls.
 * @param {Object} params.pensioner - The pensioner object containing the pensioner's details.
 * @param {Function} params.setSubscription - The function to set the subscription state.
 * @param {Function} params.setSubscriptionInvoice - The function to set the subscription invoice state.
 * @param {Function} params.setRecordID - The function to set the record ID state.
 * @param {Function} params.message - The function to display error messages.
 * @returns {Promise<void>} - A promise that resolves when the subscription and invoice data have been fetched and state has been updated.
 */
export const getSubscription = async ({
  dispatchAPI,
  pensioner,
  setSubscription,
  setSubscriptionInvoice,
  setRecordID,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/subscriptions?pensioner=${pensioner._id}&status=IN_PROGRESS&populate=invoice_file`
    });

    if (data.length) {
      setSubscription(data[0]);

      const { data: invoiceData } = await dispatchAPI('GET', {
        url: `/customerinvoices?subscription=${data[0]._id}`
      });

      if (invoiceData.length && setSubscriptionInvoice) {
        setSubscriptionInvoice(invoiceData[0]);
        setRecordID(invoiceData[0]._id);
      }
    }
  } catch (e) {
    message(e);
  }
};
