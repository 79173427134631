/**
 * Returns an array of objects containing general animal information.
 * @function
 *
 * @param {object} data - The data object containing animal information.
 * @param {function} t - The translation function.
 * @returns {Array} - An array of objects with label, span, and content properties.
 */
export const generalAnimalInfo = (data, t) => {
  const { type, name, age, breed, number } = data;

  return [
    {
      label: 'animals.form.type',
      span: 1,
      content: (type && t(`animals.tags.${type}`)) || '-'
    },
    {
      hidden: !['DOG', 'CAT'].includes(type),
      label: 'animals.form.name',
      span: 1,
      content: (name && name) || '-'
    },
    {
      hidden: !['DOG', 'CAT'].includes(type),
      label: 'animals.form.age',
      span: 1,
      content: (age && t('animals.form.age_label', { number: age })) || '-'
    },
    {
      hidden: ['DOG', 'CAT'].includes(type),
      label: 'animals.form.number',
      span: 1,
      content: (number && number) || '-'
    },
    {
      hidden: !['DOG', 'CAT'].includes(type),
      label: ['DOG', 'CAT'].includes(type)
        ? 'animals.form.breed'
        : 'animals.form.species',
      span: 1,
      content: (breed && breed) || '-'
    }
  ];
};

/**
 * Returns an array of objects containing the details of an animal.
 * @function
 *
 * @param {object} data - The data object containing the animal information.
 * @param {object} t - The translation object for localization.
 * @returns {Array} - An array of objects containing the animal details.
 */
export const detailAnimalInfo = (data, t) => {
  const {
    type,
    name,
    age,
    breed,
    sex,
    diet,
    tattoo_number,
    walk_outside_property_number,
    walk_frequency,
    walk_duration,
    other_animals_presence,
    alimentation_type_and_frequency,
    is_animal_leave_property,
    is_runaway,
    in_case_of_absence,
    treatment,
    brushing,
    where_it_sleep,
    number,
    cage_cleaning,
    additional_informations,
    box_cleaning,
    is_in_enclosure
  } = data || {};

  return [
    {
      label: 'animals.form.type',
      span: 1,
      content: (type && t(`animals.tags.${type}`)) || '-'
    },
    ...(['DOG', 'CAT'].includes(type)
      ? [
          {
            label: 'animals.form.name',
            span: 1,
            content: (name && name) || '-'
          },
          {
            label: 'animals.form.age',
            span: 1,
            content:
              (age && t('animals.form.age_label', { number: age })) || '-'
          },
          {
            label: ['DOG', 'CAT'].includes(type)
              ? 'animals.form.breed'
              : 'animals.form.species',
            span: 1,
            content: (breed && breed) || '-'
          },
          {
            label: 'animals.form.sex',
            span: 1,
            content: (sex && sex) || '-'
          },
          {
            label: 'animals.form.tattoo_number',
            span: 1,
            content: (tattoo_number && tattoo_number) || '-'
          },
          {
            label: 'animals.form.is_animal_leave_property',
            span: 1,
            content: is_animal_leave_property ? t('yes') : t('no')
          },
          {
            label: 'animals.form.is_runaway',
            span: 1,
            content: is_runaway ? t('yes') : t('no')
          },
          {
            label: 'animals.form.in_case_of_absence',
            span: 1,
            content: (in_case_of_absence && in_case_of_absence) || '-'
          },
          {
            label: 'animals.form.where_it_sleep',
            span: 1,
            content: (where_it_sleep && where_it_sleep) || '-'
          },
          {
            label: 'animals.form.brushing',
            span: 1,
            content: (brushing && brushing) || '-'
          },
          {
            label: 'animals.form.diet',
            span: 1,
            content: (diet && diet) || '-'
          }
        ]
      : [
          {
            label: 'animals.form.number',
            span: 1,
            content: (number && number) || '-'
          }
        ]),
    ...(type === 'DOG'
      ? [
          {
            label: 'animals.form.walk_outside_property_number',
            span: 1,
            content:
              (walk_outside_property_number && walk_outside_property_number) ||
              '-'
          },
          {
            label: 'animals.form.walk_frequency',
            span: 1,
            content: (walk_frequency && walk_frequency) || '-'
          },
          {
            label: 'animals.form.walk_duration',
            span: 1,
            content: (walk_duration && walk_duration) || '-'
          }
        ]
      : []),
    ...(type === 'BIRD'
      ? [
          {
            label: 'animals.form.cage_cleaning',
            span: 1,
            content: (cage_cleaning ? 'Oui' : 'Non') || '-'
          }
        ]
      : []),
    ...(['EQUINE', 'FARM'].includes(type)
      ? [
          {
            label: 'animals.form.box_cleaning',
            span: 1,
            content: (box_cleaning && box_cleaning) || '-'
          },
          {
            label: 'animals.form.is_in_enclosure',
            span: 1,
            content: (is_in_enclosure && is_in_enclosure) || '-'
          }
        ]
      : []),
    {
      label: 'animals.form.alimentation_type_and_frequency',
      span: 1,
      content:
        (alimentation_type_and_frequency && alimentation_type_and_frequency) ||
        '-'
    },
    {
      label: 'animals.form.treatment',
      span: 1,
      content: (treatment && treatment) || '-'
    },
    {
      label: 'animals.form.other_animals_presence',
      span: 1,
      content: (other_animals_presence && other_animals_presence) || '-'
    },
    {
      label: 'animals.form.additional_informations',
      span: 1,
      content: (additional_informations && additional_informations) || '-'
    }
  ];
};
